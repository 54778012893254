export function kvParallax() {
  const kv = document.querySelector('.js-kv');
  const scrollBtn = document.querySelector('.js-scrollBtn');

  const kvHeight = kv.offsetHeight;

  const pageY = window.pageYOffset;

  if (kvHeight > pageY) {
    kv.style.transform = 'translateY(-' + pageY / 5 + 'px)';
    // kv.style.opacity = 1 - pageY / 1000;
  } else if (pageY > kvHeight) {
    // kv.style.opacity = 0;
  } else {
    // kv.style.opacity = 1;
  }

  if (pageY < 10) {
    scrollBtn.classList.remove('is-hide');
  } else {
    scrollBtn.classList.add('is-hide');
  }
}
