import { toggleClass } from './toggleClass';

export function hamburger() {
  const hamburger = document.querySelector('.js-hamburger');
  const hamburgerLists = document.querySelectorAll('.js-hamburgerList');
  const hamburgerList = Array.prototype.slice.call(hamburgerLists, 0);

  const hamburgerLinks = document.querySelectorAll('.js-hamburgerLink');
  const hamburgerLink = Array.prototype.slice.call(hamburgerLinks, 0);

  const gnav = document.querySelector('.js-gnav');

  hamburger.addEventListener('click', () => {
    toggleClass(hamburger);
    toggleClass(gnav);

    if (hamburger.classList.contains('is-open')) {
      setTimeout(() => {
        hamburgerList.forEach((targetBox, index) => {
          setTimeout(() => {
            targetBox.classList.add('is-view');
          }, index * 200);
        });
      }, 300);
    } else {
      hamburgerList.forEach((targetBox, index) => {
        targetBox.classList.remove('is-view');
      });
    }
  });

  hamburgerLink.forEach(targetBox => {
    targetBox.addEventListener('click', () => {
      hamburger.classList.remove('is-open');
      gnav.classList.remove('is-open');
    });
  });
}
