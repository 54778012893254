import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';

import SweetScroll from 'sweet-scroll';

import { hamburger } from './modules/hamburger';
import { headerScroll } from './modules/headerScroll';
import { kvParallax } from './modules/kvParallax';
import { fade } from './modules/fade';

const headerHeight = document.querySelector('.js-headerInner').offsetHeight;

const sweetScroll = new SweetScroll({
  easing: 'easeOutQuint',
  offset: -1 * headerHeight,
});

hamburger();
fade();

window.addEventListener('load', () => {
  headerScroll();
});

window.addEventListener('scroll', () => {
  headerScroll();
  kvParallax();
});
