export function headerScroll() {
  const headerInner = document.querySelector('.js-headerInner');
  const kv = document.querySelector('.kv');
  const kvHeight = kv.offsetHeight;
  const headerHeight = headerInner.offsetHeight;

  const pageY = window.pageYOffset;

  if (pageY > kvHeight - headerHeight) {
    headerInner.classList.add('is-scrolled');
  } else {
    headerInner.classList.remove('is-scrolled');
  }
}
